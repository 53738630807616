
<div class="row container">
  <div class="col-xs-12">
      <div  *ngIf="!is_showing_recommendations"
      ng2-carouselamos
      class="slides-wrapper"
      [items]="item.images"
      [width]="getWindowWidth()"
      [$prev]="prev"
      [$next]="next"
      [$item]="item1"
      (onSelectedItem)="onCarouselSelectedItem($event)"
      >
      <ng-template #prev>
        <img *ngIf="show_left_button" width="20px" src="../../assets/left-arrow.png" id="left" />
      </ng-template>
      <ng-template #next>
        <img *ngIf="show_right_button && !show_recommendation_button" width="20px" src="../../assets/right-arrow.png" id="right" />
        <img *ngIf="show_recommendation_button" width="20px" src="../../assets/right-arrow-pink.png" (click)="onReccomendationButtonPressed()" />
      </ng-template>
      <ng-template #item1 let-item let-i="index">
        <div class="items">
          <img src="{{ item }}" width="{{getWindowWidth()}}">
        </div>
      </ng-template>
    </div>
    <div class="imggrid" *ngIf="is_showing_recommendations">
      <div class="dismissReccomentationButton" width="20px" (click)="onDismissReccomendationButtonPressed()">X</div>
      <div class="row text-center ingridtext">Complete your style</div>
      <div class="row text-center">
    <img class="imgingrid" src="{{ recommendedItems[0].images[0] }}" width="{{getWindowWidth()/2}}" (click)="openOutlink(recommendedItems[0].externalURL)">
    <img class="imgingrid" src="{{ recommendedItems[1].images[0] }}" width="{{getWindowWidth()/2}}" (click)="openOutlink(recommendedItems[1].externalURL)">
  </div><div class="row text-center">
    <img class="imgingrid" src="{{ recommendedItems[2].images[0] }}" width="{{getWindowWidth()/2}}" (click)="openOutlink(recommendedItems[2].externalURL)">
    <img class="imgingrid" src="{{ recommendedItems[3].images[0] }}" width="{{getWindowWidth()/2}}" (click)="openOutlink(recommendedItems[3].externalURL)">
  </div>
    </div>
  <div class="row">
    <div class="col-xs-12">
      <h1>{{ item.name }}</h1>
    </div>
  </div>
  <div *ngIf="item.size!== undefined" class="row">
    <div class="col-xs-12">
      <span class="font1">SIZE: </span> <span class="font2">{{ item.size }}</span>
    </div>
  </div>
  <div *ngIf="item.color!== undefined" class="row">
    <div class="col-xs-12">
      <span class="font1">COLOUR: </span> <span class="font2">{{ item.color }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 font3">
      £ {{ formatPrice(item.price) }}
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-xs-12 font1 overflowing" innerHtml="{{item.description}}">
      <!-- {{ item.description }} -->
    </div>
  </div>
  <hr>
  <div *ngIf="!linkWasShared" class="row text-center">
    <button class="btn cancel" type="button" (click)="onCancel()">Cancel</button>
    <div class="divider"></div>
    <button class="btn addItem" type="button" (click)="onAddItem()">Add to Bag</button>
  </div>
  <div *ngIf="linkWasShared" class="row text-center">
    <button class="btn addItem" type="button" (click)="orderOnline()">Order oline</button>
  </div>
</div>
</div>
<div class="divider"></div>
