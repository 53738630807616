import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ZXingScannerModule } from './modules/zxing-scanner/zxing-scanner.module';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';

import { AppComponent } from './app.component';
import { QrscannerComponent } from './qrscanner/qrscanner.component';
import { BagComponent } from './bag/bag.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { GenericTagLandingPageComponent } from './generic-tag-landing-page/generic-tag-landing-page.component';
import { BagListService } from './bag/bag-list.service'
import { CheckoutService } from './bag/checkout.service'
import { SelectedItemService } from './item-details/selected-item.service'
import { FetchItemService } from './item-details/fetch-item.service'

import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [
    { path: '', component: QrscannerComponent },
    { path: 'bag', component: BagComponent },
    { path: 'product', component: ItemDetailsComponent },
    { path: 'product/:sku', component: ItemDetailsComponent },
    { path: 'landing', component: GenericTagLandingPageComponent },
    { path: '**', redirectTo: '' }
];


@NgModule({
  declarations: [
    AppComponent,
    QrscannerComponent,
    BagComponent,
    ItemDetailsComponent,
    GenericTagLandingPageComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2CarouselamosModule,
    ZXingScannerModule.forRoot(),
    RouterModule.forRoot(appRoutes)
  ],
  providers: [BagListService,
              FetchItemService,
              CheckoutService,
              SelectedItemService],
  bootstrap: [AppComponent]
})
export class AppModule { }
