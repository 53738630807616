import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

import { Item } from '../shared/item.model'
import { BagListService } from '../bag/bag-list.service'
import { SelectedItemService } from './selected-item.service'

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.css']
})
export class ItemDetailsComponent implements OnInit {

  @Output() onItemAdded = new EventEmitter<string>();

  item: Item;
  recommendedItems: Item[];
  linkWasShared: Boolean = false;
  is_showing_recommendations = false;
  show_recommendation_button = false;

  show_left_button: Boolean = true;
  show_right_button: Boolean = true;

  constructor(private bagListService: BagListService,
    private selectedItemService: SelectedItemService,
    private router: Router,
    private route: ActivatedRoute) { }

    ngOnInit() {
      this.item = this.selectedItemService.getItem()
      this.selectedItemService.selectedItemChanged.subscribe(
        (item: Item) => {
          this.item = item;
          this.router.navigate(['product/' + this.item.sku]);
          if (this.item.images.length==1) {
            this.show_right_button = false;
            this.show_recommendation_button = true;
          } else {
            this.show_right_button = true;
            this.show_recommendation_button = false;
          }
        },
        (error) => console.log(error)
      );

      this.recommendedItems = this.selectedItemService.getRecommendedItems()
      this.selectedItemService.recommendedItemsChanged.subscribe(
        (items: Item[]) => {
          this.recommendedItems = items;
        },
        (error) => console.log(error)
      );

      if (this.item['sku']=='') { // in this case scanning was not done, so we load up details using the sku
        // pass qr code parameter and if it available, use it else it's shared
        if (this.route.snapshot.queryParams['barcodeId']) {
        this.selectedItemService.updateItem(this.route.snapshot.queryParams['barcodeId']);
        this.linkWasShared = false;
      } else {
        this.linkWasShared = true;
        this.selectedItemService.updateItemBySku(this.route.snapshot.params['sku']);
      }
    }
  }

  onAddItem() {
    this.bagListService.addItem(this.item);
    // this.onItemAdded.emit('bag');
    this.router.navigate(['bag']);
  }

  onCancel() {
    // this.onItemAdded.emit('qrscanner');
    this.router.navigate(['']);
  }

  getWindowWidth() {
    // console.log(window.screen.width)
    return window.screen.width - 150;
  }

  formatPrice(price: number) {
    return (price/100).toFixed(2);
  }

  orderOnline() {
    window.open(this.item.externalURL, "_blank");
  }


  openOutlink(link: string) {
      window.open(link, "_blank");
  }

  onCarouselSelectedItem(event) {
    console.log('pressed' + (event.index))
    switch(event.index) {
      case 0:
      if (this.item.images.length>1) {
        this.show_right_button = true;
        this.show_recommendation_button = false;
      } else {
        if (this.item.images.length==0) {
          this.show_right_button = false;
          this.show_recommendation_button = false;
        } else {
          this.show_right_button = false;
          this.show_recommendation_button = true;
        }
      }
      this.show_left_button = false;
      //
      break;
      case (this.item.images.length-1):
      this.show_right_button = false;
      this.show_left_button = true;
      this.show_recommendation_button = true;
      break;
      default:
      this.show_right_button = true;
      this.show_left_button = true;
      this.show_recommendation_button = false;
    }
  }

  onReccomendationButtonPressed() {
    console.log('Reccomendation pressed')
    this.is_showing_recommendations = true;
  }

  onDismissReccomendationButtonPressed() {
    console.log('Reccomendation dimissed')
    this.show_recommendation_button = false;
    this.is_showing_recommendations = false;
  }
}
