// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// ng build --aot
// to push to aws s3
// /Users/Stefio/Library/Python/2.7/bin/aws s3 ls
// /Users/Stefio/Library/Python/2.7/bin/aws s3 ls s3://webapp.skipq.io
// /Users/Stefio/Library/Python/2.7/bin/aws s3 rm s3://webapp.skipq.io --recursive
// /Users/Stefio/Library/Python/2.7/bin/aws s3 cp /Users/Stefio/Dropbox/skipQ/github/web/qr-reader-webapp/dist/qr-test s3://webapp.skipq.io --recursive --acl public-read
