<div class="container">
  <div class="row">
    <div class="col-md-12">
      <!-- <app-qrscanner *ngIf="loadedFeature === 'qrscanner'" (onQRcodeFound)="onNavigate($event)"></app-qrscanner>
      <app-bag *ngIf="loadedFeature === 'bag'" (onCancelPressed)="onNavigate($event)"></app-bag>
      <app-item-details *ngIf="loadedFeature === 'item-details'"  (onItemAdded)="onNavigate($event)"></app-item-details> -->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
