export class Item {
  public name: string;
  public description: string;
  public images: string[];
  public price: number;
  public code: string;
  public size: string;
  public color: string;
  public sku: string;
  public externalURL: string;

  constructor(name: string, desc: string, images: string[], price: number, code: string, size: string, colour: string, sku: string, externalURL: string) {
    this.name = name;
    this.description = desc;
    this.images = images;
    this.price = price;
    this.code = code;
    this.size = size;
    this.color = colour;
    this.sku = sku;
    this.externalURL = externalURL;
  }
}
