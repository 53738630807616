import { EventEmitter } from '@angular/core'

import { Item } from '../shared/item.model';

export class BagListService {
  // this one keeps track if a product is added to update the data
  // other option would be to use the same data from get products instead
  // of a copy (i.e. remove slice)
  itemsChanged = new EventEmitter<Item[]>();

  private items: Item[] = [];

  getItems() {
    return this.items.slice();
  }

  addItem(item: Item) {
    var id = this.items.length + 1;
    var found = this.items.some(function (el) {
    return el.sku === item.sku;
  });
    if (!found) {
      this.items.push(item);
      this.itemsChanged.emit(this.items.slice());
    }
  }

  removeItem(indexOf: number) {
    this.items.splice(indexOf, 1);
    this.itemsChanged.emit(this.items.slice());
  }

  removeAllItems() {
    this.items = [];
  }

}
