import { EventEmitter, Injectable } from '@angular/core'
import { Response } from '@angular/http'
import { Router } from '@angular/router'

import { FetchItemService } from './fetch-item.service'
import { Item } from '../shared/item.model';

@Injectable()
export class SelectedItemService {
  // this one keeps track if a product is added to update the data
  // other option would be to use the same data from get products instead
  // of a copy (i.e. remove slice)
 selectedItemChanged = new EventEmitter<Item>();
 recommendedItemsChanged = new EventEmitter<Item[]>();

  private recommendedItems: Item[] = [];
  private item: Item =
    new Item('',
    '',
    ['../../assets/loading_icon.gif'],
    NaN,
    '',
    '',
    '',
    '',
    '');

  constructor(private fetchItemService: FetchItemService,
  private router: Router) { }

  getItem() {
    return this.item;
  }

  getRecommendedItems() {
    return this.recommendedItems;
  }

  updateItem(qrcode: string) {

    this.fetchItemService.getItem(qrcode).
    subscribe(
      (response: Response) => {
        // check if its event or has a different type and add it as 'extra item detail'


        // console.log(response.json());
        const newItem = new Item(response.json()['name'],
        response.json()['description'],
        this.getFields(response.json()['images'],'url'),
        response.json()['price']['valueInCents'],
        qrcode,
        response.json()['size'],
        response.json()['color'],
        response.json()['sku'],
        response.json()['externalURL']);
        this.item = newItem;
        this.selectedItemChanged.emit(newItem);
        this.updateReccomendations(qrcode)
      },
      (error) =>  this.router.navigate([''])
    );

  }

  updateItemBySku(sku: string) {

    this.fetchItemService.getItemBySku(sku).
    subscribe(
      (response: Response) => {
        // console.log(response.json());
        const newItem = new Item(response.json()['name'],
        response.json()['description'],
        this.getFields(response.json()['images'],'url'),
        response.json()['price']['valueInCents'],
        '',
        response.json()['size'],
        response.json()['color'],
        sku,
        response.json()['externalURL']);
        this.item = newItem;
        // console.log(this.item);
        this.selectedItemChanged.emit(newItem);
        this.updateReccomendations(sku)
      },
      (error) =>  this.router.navigate([''])
    );

  }

  updateReccomendations(sku: string) {

    this.recommendedItems = [];
    this.fetchItemService.getRecommendations(sku).
    subscribe(
      (response: Response) => {
        // console.log(response.json())
        var arrayLength = response.json().length;
        for (var i = 0; i < arrayLength; i++) {
        // console.log(response.json());
          const newItem = new Item(response.json()[i]['name'],
          response.json()[i]['description'],
          this.getFields(response.json()[i]['images'],'url'),
          response.json()[i]['price']['valueInCents'],
          response.json()[i]['sku'],
          response.json()[i]['size'],
          response.json()[i]['color'],
          response.json()[i]['sku'],
          response.json()[i]['externalURL']);
          this.recommendedItems.push(newItem);
      }
      // console.log(this.recommendedItems);
      this.recommendedItemsChanged.emit(this.recommendedItems)
      },
      (error) =>  this.router.navigate([''])
    );

  }

  getFields(input, field) {
      var output = [];
      for (var i=0; i < input.length ; ++i)
          output.push(input[i][field]);
      return output;
  }

}
