import { Injectable } from '@angular/core'
import { Headers,  Http } from '@angular/http'

import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Item } from '../shared/item.model';

@Injectable()
export class FetchItemService {

  constructor(private http: Http) { }

  getItem(qrcode: string) {
    const headers = new Headers();
    return this.http.get('https://api.skipq.io/product?barcodeId=' + qrcode, {headers: headers});
  }

  getItemBySku(sku: string) {
    const headers = new Headers();
    return this.http.get('https://api.skipq.io/product/' + sku, {headers: headers});
  }

  getRecommendations(sku: string) {
    const headers = new Headers();
    return this.http.get('https://api.skipq.io/recommendation/' + sku, {headers: headers});
  }

}
