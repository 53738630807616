import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Response } from '@angular/http';
import { Router } from '@angular/router';

import { Item } from '../shared/item.model';
import { BagListService } from './bag-list.service';
import { CheckoutService } from '../bag/checkout.service';

@Component({
  selector: 'app-bag',
  templateUrl: './bag.component.html',
  styleUrls: ['./bag.component.css']
})
export class BagComponent implements OnInit {

  @Output() onCancelPressed = new EventEmitter<string>();

  items: Item[];
  showAlert: boolean = false;
  showPaymentForm: boolean = false;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cvc: string;
  errorMessage = '';

  constructor(private bagListService: BagListService,
  private checkoutService: CheckoutService,
  private router: Router,
  private fb: FormBuilder) { }

    ngOnInit() {
      this.items = this.bagListService.getItems()
      this.bagListService.itemsChanged.subscribe(
        (items: Item[]) => {
          this.items = items;
        }
      );
      }

getToken() {
  if (this.cardNumber== undefined || this.cardNumber.length<10) {
    this.errorMessage = "Please provide a valid card number.";
    return;
  }
  if (this.expiryMonth== undefined || this.expiryMonth.length<2) {
    this.errorMessage = "Please provide the expiry month in MM.";
    return 0;
  }
  if (this.expiryYear== undefined || this.expiryYear.length<2) {
    this.errorMessage = "Please provide the expiry year in YY.";
    return 0;
  }
  var self = this;
  (<any>window).Stripe.card.createToken({
    number: this.cardNumber,
    exp_month: this.expiryMonth,
    exp_year: this.expiryYear,
    cvc: this.cvc
  }, (status: number, response: any) => {
    if (status === 200) {

      this.errorMessage = "Payment in progress..";

      console.log(response.card.id)
      self.checkoutService.checkout(self.items,response.id, self.getTotalToPay()).
      subscribe(
        (response: Response) => {
          console.log(response.json());
          self.items = [];
          self.removeAllItems();
          this.showPaymentForm = false;
          self.showAlert = true;
          this.errorMessage = "";
        },
        (error) => {
          console.log(error)
          self.errorMessage = "There was an error. Please try again.";
      }
      );

    } else {
      console.log(response.error);
      this.errorMessage = response.error.message
    }
  });
}

getCardInfo() {
  this.showPaymentForm = true;
}

formatPrice(price: number) {
  return (price/100).toFixed(2);
}

getPayButtonString() {
  return "Pay £" + this.formatPrice(this.getTotalToPay());
}

cancelCardPayment() {
  this.showPaymentForm = false;
}

hideAlert() {
  this.showAlert = false;
}
getTotalToPay() {
  if (this.items == null) {
    return 0;
  }
  return this.items.reduce(function (a, b) {
    return b['price'] == null ? a : a + b['price'];
  }, 0);
};

removeAllItems() {
  this.bagListService.removeAllItems();
}

onRemoveItem(event) {
  console.log('remove')
  this.bagListService.removeItem(event.toElement.getAttribute('data-index'))
}

onCancel() {
  // this.onCancelPressed.emit('qrscanner');
  this.router.navigate(['']);
}

onItemTapped(code: string) {
  console.log("item tapped")
  // this.onCancelPressed.emit('qrscanner');
  // this.router.navigate(['/item/' + code]);
}

splitter(str, l){
    var strs = [];
    while(str.length > l){
        var pos = str.substring(0, l).lastIndexOf(' ');
        pos = pos <= 0 ? l : pos;
        strs.push(str.substring(0, pos));
        var i = str.indexOf(' ', pos)+1;
        if(i < pos || i > pos+l)
            i = pos;
        str = str.substring(i);
    }
    strs.push(str);
    return strs;
}

}
