<div class="landing-main">
  <img src="../../assets/ldc_logo_square.svg" height="100dp">
<div class="main">
  Download the Lone Design Collective app to stay up-to-date with our community, order online or pay with your phone.
</div>
<img src="../../assets/mockups.png" height="150dp">
<div>
  <div class="links">
  <a href="https://itunes.apple.com/gb/app/ldc/id1444791502?mt=8"><img src="../../assets/applestore.svg" height="40dp"></a>
  &nbsp;
  <a href="https://play.google.com/store/apps/details?id=com.skipq.skipq.ldc"><img src="../../assets/playstore.svg" height="40dp"></a>
  </div>
</div>
</div>
