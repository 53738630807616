import { Injectable } from '@angular/core'
import { Http } from '@angular/http';

import { Item } from '../shared/item.model';

@Injectable()
export class CheckoutService {
  // this one keeps track if a product is added to update the data
  // other option would be to use the same data from get products instead
  // of a copy (i.e. remove slice)
  // stripe = Stripe("pk_test_dOjs52mwAQjC9cg4YEg5terO")

  constructor(private http: Http) {}

  checkout(items: Item[], tokenId: string, value: number) {

   const jsondata = { tokenOverride: tokenId ,
      transactionItems: items.map(function (item) { return { sku: item.code, barcode: item.code }}),
      totalPrice: { currency: "gbp", valueInCents: value }
     };

     // console.log(jsondata)

    return this.http.post('https://api.skipq.io/transaction/oneoff/' + this.guid(), jsondata);
    // return this.http.put('http://localhost:8080/transaction/oneoff/' + this.guid(), jsondata);

  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }


}
