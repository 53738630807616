<div class="container">
  <div *ngIf="!showPaymentForm">
  <div class="row">
  <div class="col-xs-12">
    <h1 *ngIf="items.length > 0" class="center">MY BAG ({{items.length}})</h1>
    <h1 *ngIf="items.length == 0" class="center">MY BAG</h1>
  </div>
</div>
<div *ngIf="showAlert" class="alert">
  <span class="closebtn" (click)="hideAlert()">&times;</span>
  Payment successful, thank you!
</div>
<div *ngIf="items.length > 0" class="row">
  <!-- <div class="col-xs-12"> -->
    <div *ngIf="items.length > 0" class="table-responsive">
      <table class="table borderless">
        <tr *ngFor="let item of items; let i = index">
          <td class="detailtd" (click)="onItemTapped(item.code)">
              <img *ngIf="item.images.length > 0" class="detailimage" src={{item.images[0]}} alt="img">
              <div class="detailsspan" style="z-index:-1">
                <span class="font2"> {{splitter(item.name,25)[0]}}</span>
                <span *ngIf="item.name.length>25" class="font4">{{splitter(item.name,25)[1]}}</span>
                <div></div>
                <span class="font4"><span *ngIf="item.size!== undefined" class="font1">SIZE: </span> <span class="font22">{{ item.size }}</span></span>
            </div>
          </td>
          <td class="closebtnandprice">
              <span class="closebuttonspan"><button class="btn btn2" [attr.data-index]="i" type="button" (click)="onRemoveItem($event)">x</button></span>
              <!-- <span class="divspan"></span> -->
              <span class="font3">£ {{formatPrice(item.price)}}</span>
          </td>
        </tr>
      </table>
    </div>
  <!-- </div> -->
</div>
<!-- <div *ngIf="items.length == 0" class="col-xs-12"> -->
  <img *ngIf="items.length == 0" src="../assets/design/illustration@3x.png" class="center emptyBag" alt="EmptyBag">
<!-- </div> -->
<div *ngIf="items.length == 0" class="emptyString1">
  YOUR BAG IS EMPTY
</div>
<div *ngIf="items.length == 0" class="emptyString2">
  Tap scan to go shopping
</div>
<div class="row text-center nextaction">
        <button class="btn cancel" type="button" (click)="onCancel()">Scan</button>
        <div *ngIf="items.length > 0" class="divider"></div>
        <button *ngIf="items.length > 0" class="btn pay" type="button" (click)="getCardInfo()">Pay £ {{ formatPrice(getTotalToPay()) }}</button>
</div>
</div>
<div *ngIf="showPaymentForm">
  <form action="" method="POST" id="payment-form" (submit)="getToken()">
    <div class="row"><div class="col-xs-12"><h1 class="center">PAYMENT</h1></div></div>
      <div class="form-row row" style="padding-bottom:15px;text-align:center">Please provide your card details to pay.</div>
    <div class="form-row row">
      <label>
        <span>Card Number</span>
        <input [(ngModel)]="cardNumber" name="card-number" type="text" size="20" data-stripe="number">
      </label>
    </div>
    <div class="form-row row">
      <label>
        <span>Expiration (MM/YY)</span>
        <input [(ngModel)]="expiryMonth" name="expiry-month" type="text" size="2" data-stripe="exp_month">
      </label>
      <span> / </span>
      <input [(ngModel)]="expiryYear" name="expiry-year" type="text" size="2" data-stripe="exp_year">
    </div>
    <div class="form-row row">
      <label>
        <span>CVC</span>
        <input [(ngModel)]="cvc" name="cvc" type="text" size="4" data-stripe="cvc">
      </label>
    </div>
    <div class="form-row row" style="padding-bottom:15px;padding-top:15px;text-align:center;">
    {{errorMessage}}
    </div>
    <div class="row text-center nextaction">
    <button class="btn cancel" type="button" (click)="cancelCardPayment()">Cancel</button>
    <div class="divider"></div>
    <button class="btn pay" type="submit button">{{ getPayButtonString() }}</button>
    </div>
  </form>
</div>
</div>
