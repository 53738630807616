import { Component, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '../modules/zxing-scanner/zxing-scanner.module';
import { Result } from '@zxing/library';
import { Router } from '@angular/router';

import { Item } from '../shared/item.model'
import { SelectedItemService } from '../item-details/selected-item.service'

@Component({
  selector: 'app-qrscanner',
  templateUrl: './qrscanner.component.html',
  styleUrls: ['./qrscanner.component.css']
})

export class QrscannerComponent implements OnInit {

  @Output() onQRcodeFound = new EventEmitter<string>();

  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo;
  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  qrResult: Result;
  // devicesLabels: string[] = [];

  constructor(private selectedItemService: SelectedItemService,
  private router: Router) { }

  ngOnInit(): void {
      this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
          this.availableDevices = devices;

          // select the first available device just in case
          // for (const device of devices) {
          //   this.devicesLabels.push(device.label);
          // }
          this.scanner.changeDevice(devices[1]);
          this.currentDevice = devices[1];

          // searches the devices's back camera
          for (var device of devices) {
            // console.log(device.label);
              if (/FaceTime HD Camera|back|rear/gi.test(device.label)) {
                  this.scanner.changeDevice(device);
                  this.currentDevice = device;
                  // break;
              }
          }

      });

      // console.log(this.scanner);
      this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => this.availableDevices = devices);
      this.scanner.hasDevices.subscribe((has: boolean) => this.hasDevices = has);
      this.scanner.scanComplete.subscribe((result: Result) => this.qrResult = result);
      this.scanner.permissionResponse.subscribe((perm: boolean) => this.hasPermission = perm);

      // this.handleQrCodeResult("15708517105779")//14792817999987

      var self = this;
      this.selectedItemService.selectedItemChanged.subscribe(
        (item: Item) => {
          self.router.navigate(['product/' + item['sku']]);
        },
        (error) => console.log(error)
      );
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
      console.debug('Devices: ', cameras);
      this.availableDevices = cameras;
  }

  handleQrCodeResult(resultString: string) {
        console.debug('Result: ', resultString);
        const temp = resultString.split('barcodeId='); // new barcodes with ?barcodeId=LDC333333
        if (temp.length>1) { // supports old barcodes, if there is no equal, use the full string as barcode
          resultString = temp[temp.length-1];
        }
        this.qrResultString = resultString;
        this.selectedItemService.updateItem(this.qrResultString);
        // not doing update of recc. when scanning qrcode.. on mobile only? why?
        // this.selectedItemService.updateReccomendations(this.qrResultString);
        // this.onQRcodeFound.emit('item-details');
    }

    onViewBag() {
      // this.onQRcodeFound.emit('bag');
      this.router.navigate(['bag']);
    }

}
